const OctMemeReelsTranslations = {
    en : {
        heading1: "Let’s get creative and talk the language we all understand – MEMES and Reels!",
        heading2: "Express your understanding of cybersecurity in a funny and witty manner",
        heading3: "Winners will be chosen, based on the following parameters:",
        heading4: "Make sure that you have permission of every person that is present on the picture. If you want more information about the way we process these pictures and your personal data, you can always check the employee privacy notice.",
        heading5: "contest privacy notice:", 
        cardText1: "The meme/reel you make can be a photo or a video not exceeding 15 seconds",
        cardText2: "Make it funny, make it cyber! Your understanding of topics will come into play",
        cardText3: "Make sure that your have permission of every person that is present on the picture. If you want more information about the way we process these pictures and videos and your personal data, you can always check the employee privacy note",
        contestTags: "#DoYourPart #BeCyberSmart",
        guidelines: "Guidelines",
        listItem1: "Only ABI colleagues can participate in this contest",
        listItem2: "Only 1 entry per colleague",
        listItem3: "Make sure to submit your entries before 30 October 2024 ",
        listItem4: "The memes you create will be displayed in the gallery section at the end of the month with due credit ",
        listItem5: "Make sure that your have permission of every person that is present on the picture. If you want more information about the way we process these pictures and videos and your personal data, you can always check the employee privacy note  ",
        listItem6: "30 October, 2024",
        number1: "Uniqueness and creativity",
        number2: "Clarity of Concept being used in the meme/reel",
        formSubHeading: "SUBMIT YOUR",
        formHeading: "ENTRIES HERE",
        label1: "Employee ID",
        label2: "Upload File",
        label3: "Comment",
        plaeholder1: "Your Employee ID Here....",
        plaeholder2: "Your Comment Here....",
        submitbtn: "Submit",
        formFooterText: "Entries once submitted cannot be edited",
        guidelineButtonText: 'For More Information Click Here',
    },
    es : {
        heading1: "¡Vamos a ser creativos y hablar el idioma que todos entendemos: MEMES y Reels!",
        heading2: "La ciberseguridad es como llevar pantalones a una reunión de Zoom: nadie lo ve, pero créeme, realmente no quieres olvidarlo.",
        heading3: "Los ganadores serán seleccionados según los siguientes parámetros:",
        heading4: "Asegúrate de tener el permiso de cada persona que aparece en la imagen. Si deseas más información sobre cómo procesamos estas imágenes y tus datos personales, siempre puedes consultar la nota de privacidad para empleados.",
        heading5: "Aviso de privacidad del concurso:",
        cardText1: "La ciberseguridad es como llevar pantalones a una reunión de Zoom: nadie lo ve, pero créeme, realmente no quieres olvidarlo.",
        cardText2: "¡Hazlo divertido, hazlo ciber! Tu comprensión de los temas entrará en juego.",
        cardText3: "Asegúrate de tener el permiso de cada persona que aparece en la imagen. Si deseas más información sobre cómo procesamos estas imágenes y videos, así como tus datos personales, siempre puedes consultar la nota de privacidad para empleados.",
        contestTags: "#HazTuParte #SéCiberInteligente",
        guidelines: "Directrices",
        listItem1: "Solo los colegas de ABI pueden participar en este concurso.",
        listItem2: "Solo 1 participación por colega",
        listItem3: "Asegúrate de enviar tus participaciones antes del 30 de octubre de 2024.",
        listItem4: "Los memes que crees se mostrarán en la sección de la galería al final del mes con el debido crédito.",
        listItem5: "Asegúrate de tener el permiso de cada persona que aparece en la imagen. Si deseas más información sobre cómo procesamos estas imágenes y videos, así como tus datos personales, siempre puedes consultar la nota de privacidad para empleados.",
        listItem6: "30 de octubre de 2024",
        number1: "Unicidad y creatividad",
        number2: "Clareza do conceito utilizado no meme/reel",
        formSubHeading: "ENVÍA TUS",
        formHeading: "ENTRADAS AQUÍ",
        label1: "Identificación del empleado",
        label2: "Subir archivo",
        label3: "Comentario",
        plaeholder1: "Tu identificación de empleado aquí....",
        plaeholder2: "Tu comentario aquí....",
        submitbtn: "Enviar",
        formFooterText: "Las entradas una vez enviadas no se pueden editar",
        guidelineButtonText: "Para más información, haz clic aquí",
    },
    pt: {
        heading1: "Vamos ser criativos e falar a língua que todos entendemos: MEMES e Reels!",
        heading2: "A cibersegurança é como usar calças numa reunião de Zoom—ninguém vê, mas acredite, você realmente não quer esquecer.",
        heading3: "Os vencedores serão escolhidos com base nos seguintes parâmetros:",
        heading4: "Certifique-se de ter a permissão de cada pessoa que está presente na imagem. Se desejar mais informações sobre a forma como processamos estas imagens e os seus dados pessoais, pode sempre consultar o aviso de privacidade do funcionário.",
        heading5: "Aviso de privacidade do concurso:",
        cardText1: "A cibersegurança é como usar calças numa reunião de Zoom—ninguém vê, mas acredite, você realmente não quer esquecer.",
        cardText2: "Faça engraçado, faça ciber! Seu entendimento dos assuntos vai entrar em jogo.",
        cardText3: "Certifique-se de que tem a permissão de todas as pessoas que aparecem na imagem. Se quiser mais informações sobre como processamos estas imagens e vídeos e seus dados pessoais, você pode sempre consultar a nota de privacidade do funcionário.",
        guidelines: "Diretrizes",
        listItem1: "Apenas os colegas da ABI podem participar deste concurso.",
        listItem2: "Apenas 1 participação por colega",
        listItem3: "Certifique-se de enviar suas inscrições antes de 30 de outubro de 2024.",
        listItem4: "Os memes que você criar serão exibidos na seção de galeria no final do mês com os devidos créditos.",
        listItem5: "Certifique-se de que tem a permissão de todas as pessoas que aparecem na imagem. Se quiser mais informações sobre como processamos estas imagens e vídeos e seus dados pessoais, você pode sempre consultar a nota de privacidade do funcionário.",
        listItem6: "30 de outubro de 2024",
        number1: "Singularidade e criatividade",
        number2: "Clareza do conceito utilizado no meme/reel",
        formSubHeading: "ENVIE SUAS",
        formHeading: "ENTRADAS AQUI",
        label1: "Identificação do funcionário",
        label2: "Carregar arquivo",
        label3: "Comentário",
        plaeholder1: "Sua identificação de funcionário aqui....",
        plaeholder2: "Seu comentário aqui....",
        submitbtn: "Enviar",
        formFooterText: "As inscrições, uma vez enviadas, não podem ser editadas",
        guidelineButtonText: "Para mais informações, clique aqui",
    },
    zh: {
        heading1: "让我们发挥创意，用所有人都懂的语言交流——MEMES 和 Reels！",
        heading2: "网络安全就像在 Zoom 会议上穿裤子——没人看得见，但相信我，你绝对不想忘记！",
        heading3: "获胜者将根据以下参数进行选择：",
        cardText1: "网络安全就像在 Zoom 会议上穿裤子——没人看得见，但相信我，你绝对不想忘记！",
        cardText2: "让它变得有趣，让它变得网络化！你对这些话题的理解将派上用场",
        cardText3: "请确保您已经获得照片中每个人的许可。如果您想了解我们如何处理这些图片和视频以及您的个人数据，您可以随时查看员工隐私说明。",
        heading4: "请确保您已经获得照片中每个人的许可。如果您想了解我们如何处理这些图片和视频以及您的个人数据，您可以随时查看员工隐私说明。",
        heading5: "比赛隐私声明：",
        contestTags: "#尽你的一份力 #成为网络安全专家",
        guidelines: "指南",
        listItem1: "只有ABI同事可以参加此比赛。",
        listItem2: "每位同事仅限1次参赛",
        listItem3: "请确保在2024年10月30日之前提交您的参赛作品。",
        listItem4: "您创作的表情包将在月底的画廊部分展示，并给予应有的署名。",
        listItem5: "请确保您已经获得照片中每个人的许可。如果您想了解我们如何处理这些图片和视频以及您的个人数据，您可以随时查看员工隐私说明。",
        listItem6: "2024年10月30日",
        number1: "独特性与创造力",
        number2: "梗图/短片中使用的概念清晰度",
        formSubHeading: "提交您的",
        formHeading: "参赛作品",
        label1: "员工编号",
        label2: "上传文件",
        label3: "评论",
        plaeholder1: "在此处输入您的员工编号....",
        plaeholder2: "在此处输入您的评论....",
        submitbtn: "提交",
        formFooterText: "提交后，无法编辑参赛作品",
        guidelineButtonText: "更多信息，请点击这里",
    }
}

export default OctMemeReelsTranslations;