import AdminPageLoader from 'csam/admin/components/AdminPageLoader';
import AddHomeContent from 'csam/admin/pages/AddHomeContent';
import AddBanner from 'csam/admin/pages/banners/AddBanner';
import Banners from 'csam/admin/pages/banners/Banners';
import AddCalendarMonth from 'csam/admin/pages/CalendarMonth/AddCalenderMonth';
import CalendarMonths from 'csam/admin/pages/CalendarMonth/CalendarMonths';
import AddCalendarWeek from 'csam/admin/pages/calendarWeeks/AddCalendarWeek';
import CalendarWeeks from 'csam/admin/pages/calendarWeeks/CalendarWeeks';
import AddCalendarYear from 'csam/admin/pages/CalendarYear/AddCalendarYear';
import CalendarYears from 'csam/admin/pages/CalendarYear/CalendarYears';
import AddDataProtectionTips from 'csam/admin/pages/data-protection/AddDataProtectionTips';
import AddDataProtectionTrainings from 'csam/admin/pages/data-protection/AddDataProtectionTrainings';
import AddDataProtectionBanner from 'csam/admin/pages/data-protection/AddDataProtectionBanner';
import DataProtectionTips from 'csam/admin/pages/data-protection/DataProtectionTips';
import DataProtectionTraining from 'csam/admin/pages/data-protection/DataProtectionTraining';
import AddGames from 'csam/admin/pages/games/AddGames';
import Games from 'csam/admin/pages/games/Games';
import AddDigitalEthics from 'csam/admin/pages/gisp-resources/AddDigitalEthics';
import AddGispPolicy from 'csam/admin/pages/gisp-resources/AddGispPolicy';
import AddQuickTools from 'csam/admin/pages/gisp-resources/AddQuickTools';
import DigitalEthics from 'csam/admin/pages/gisp-resources/DigitalEthics';
import GispPolicy from 'csam/admin/pages/gisp-resources/GispPolicy';
import GispQuickTools from 'csam/admin/pages/gisp-resources/GispQuickTools';
import AddArticles from 'csam/admin/pages/good-reads/AddArticles';
import AddSuggestedVideos from 'csam/admin/pages/good-reads/AddSuggestedVideos';
import GoodReads from 'csam/admin/pages/good-reads/GoodReads';
import SuggestedVideos from 'csam/admin/pages/good-reads/SuggestedVideos';
import AddOppsEmail from 'csam/admin/pages/opps/AddOppsEmail';
import Opps from 'csam/admin/pages/opps/OppsEmail';
import AddOptions from 'csam/admin/pages/Options/AddOptions';
import Options from 'csam/admin/pages/Options/Options';
import AddQuertyInVedor from 'csam/admin/pages/querty-in-veder/AddQuertyInVedor';
import QuertyInVeder from 'csam/admin/pages/querty-in-veder/QuertyInVeder';
import AddQuizQuestions from 'csam/admin/pages/questions/AddQuizQuestions';
import Questions from 'csam/admin/pages/questions/Questions';
import AddQuiz from 'csam/admin/pages/quiz/AddQuiz';
import QuizContests from 'csam/admin/pages/quiz/QuizContests';
import Survay from 'csam/admin/pages/survey/Survay';
import { useAdminAuthorized } from 'csam/api/api';
import { lazy, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import UserActivities from 'csam/admin/pages/user activities/UserActivities';
import DataProtectionBanner from './admin/pages/data-protection/DataProtectionBanner';
import OrganizationStructure from 'csam/admin/pages/data-protection/OrganizationStructure';
import QuizResults from 'csam/admin/pages/quiz/QuizResults';
import AddOrganizationStructure from 'csam/admin/pages/data-protection/AddOrganizationStructure';
import DataProtectionGoodReads from 'csam/admin/pages/data-protection/DataProtectionGoodReads';
import AddDataProtectionGoodReads from 'csam/admin/pages/data-protection/AddDataProtectionGoodReads';
import DataProtectionUserActivity from './admin/pages/data-protection/DataProtectionUserActivity';
import OctoberBanners from './admin/pages/october/october-banners/OctoberBanners';
import AddOctBanners from './admin/pages/october/october-banners/AddOctBanners';
import OctGames from './admin/pages/october/games/OctGames';
import AddOctGames from './admin/pages/october/games/AddOctGames';
import WhyWhatHow from './admin/pages/october/why-what-how/WhyWhatHow';
import AddWhatWhyHow from './admin/pages/october/why-what-how/AddWhatWhyHow';
import OctPledgeVideo from './admin/pages/october/oct-pledge/OctPledgeVideo';
import AddOctPledgeVideo from './admin/pages/october/oct-pledge/AddOctPledgeVideo';
// import OctDay from './admin/pages/october/calendar/octDays';
// import AddOctDays from './admin/pages/october/calendar/AddOctDays';
import OctWeeks from './admin/pages/october/calendar/octWeeks';
import AddOctWeeks from './admin/pages/october/calendar/AddOctWeeks';
import OctDay from './admin/pages/october/calendar/octDays';
import AddOctDays from './admin/pages/october/calendar/AddOctDays';
import Webinar from './admin/pages/october/webinar/Webinar';
import AddWebinars from './admin/pages/october/webinar/AddWebinars';
import OctGallary from './admin/pages/october/gallary/OctGallary';
import AddOctGallary from './admin/pages/october/gallary/AddOctGallary';
import OctContest from './admin/pages/october/contest/OctContest';
import AddOctContests from './admin/pages/october/contest/AddOctContests';
import OctContestsGuidelines from './admin/pages/october/oct-guidelines/OctContestsGuidelines';
import AddOctContestsGuidelines from './admin/pages/october/oct-guidelines/AddOctContestsGuidelines';
import AllActivites from './admin/activites/AllActivites';
import AddNewActivity from './admin/activites/AddNewActivity';

const Dashboard = lazy(() => import('csam/admin/pages/Dashboard'));
const Roles = lazy(() => import('csam/admin/pages/Roles'));
interface ProtectedRouteProps {
  children: ReactNode;
}
const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { isAdmin, isLoading } = useAdminAuthorized();
  const location = useLocation();

  if (isLoading) {
    return <AdminPageLoader />;
  }

  if (!isAdmin) {
    return <Navigate to="/404" state={{ from: location }} replace />;
  }

  return children;
};

const adminRoutes = [
  {
    path: '/admin',
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/roles',
    element: (
      <ProtectedRoute>
        <Roles />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/calendar/years',
    element: (
      <ProtectedRoute>
        <CalendarYears />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/calendar/years/create',
    element: (
      <ProtectedRoute>
        <AddCalendarYear />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/calendar/years/edit/:id',
    element: (
      <ProtectedRoute>
        <AddCalendarYear />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/calendar/months',
    element: (
      <ProtectedRoute>
        <CalendarMonths />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/calendar/months/create',
    element: (
      <ProtectedRoute>
        <AddCalendarMonth />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/calendar/months/edit/:id',
    element: (
      <ProtectedRoute>
        <AddCalendarMonth />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/calendar/weeks',
    element: (
      <ProtectedRoute>
        <CalendarWeeks />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/calendar/weeks/create',
    element: (
      <ProtectedRoute>
        <AddCalendarWeek />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/calendar/weeks/edit/:id',
    element: (
      <ProtectedRoute>
        <AddCalendarWeek />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/quiz/create',
    element: (
      <ProtectedRoute>
        <AddQuiz />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/quiz-results',
    element: (
      <ProtectedRoute>
        <QuizResults />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/quiz/edit/:id',
    element: (
      <ProtectedRoute>
        <AddQuiz />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/quiz',
    element: (
      <ProtectedRoute>
        <QuizContests />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/questions/create',
    element: (
      <ProtectedRoute>
        <AddQuizQuestions />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/questions',
    element: (
      <ProtectedRoute>
        <Questions />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/questions/edit/:id',
    element: (
      <ProtectedRoute>
        <AddQuizQuestions />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/options',
    element: (
      <ProtectedRoute>
        <Options />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/options/create',
    element: (
      <ProtectedRoute>
        <AddOptions />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/options/edit/:id',
    element: (
      <ProtectedRoute>
        <AddOptions />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/games',
    element: (
      <ProtectedRoute>
        <Games />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/games/create',
    element: (
      <ProtectedRoute>
        <AddGames />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/games/edit/:id',
    element: (
      <ProtectedRoute>
        <AddGames />
      </ProtectedRoute>
    ),
  },

  {
    path: 'admin/goodreads',
    element: (
      <ProtectedRoute>
        <GoodReads />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/goodreads/create',
    element: (
      <ProtectedRoute>
        <AddArticles />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/goodreads/edit/:id',
    element: (
      <ProtectedRoute>
        <AddArticles />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/suggested-videos',
    element: (
      <ProtectedRoute>
        <SuggestedVideos />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/suggested-videos/create',
    element: (
      <ProtectedRoute>
        <AddSuggestedVideos />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/suggested-videos/edit/:id',
    element: (
      <ProtectedRoute>
        <AddSuggestedVideos />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/banners',
    element: (
      <ProtectedRoute>
        <Banners />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/banners/create',
    element: (
      <ProtectedRoute>
        <AddBanner />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/banners/edit/:id',
    element: (
      <ProtectedRoute>
        <AddBanner />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/gisp-policy',
    element: (
      <ProtectedRoute>
        <GispPolicy />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/gisp-policy/create',
    element: (
      <ProtectedRoute>
        <AddGispPolicy />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/gisp-policy/edit/:id',
    element: (
      <ProtectedRoute>
        <AddGispPolicy />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/digital-ethics',
    element: (
      <ProtectedRoute>
        <DigitalEthics />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/digital-ethics/create',
    element: (
      <ProtectedRoute>
        <AddDigitalEthics />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/digital-ethics/edit/:id',
    element: (
      <ProtectedRoute>
        <AddDigitalEthics />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/quick-tools',
    element: (
      <ProtectedRoute>
        <GispQuickTools />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/quick-tools/create',
    element: (
      <ProtectedRoute>
        <AddQuickTools />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/quick-tools/edit/:id',
    element: (
      <ProtectedRoute>
        <AddQuickTools />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/data-protection-tips',
    element: (
      <ProtectedRoute>
        <DataProtectionTips />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/data-protection-tips/create',
    element: (
      <ProtectedRoute>
        <AddDataProtectionTips />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/data-protection-tips/edit/:id',
    element: (
      <ProtectedRoute>
        <AddDataProtectionTips />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/data-protection-training',
    element: (
      <ProtectedRoute>
        <DataProtectionTraining />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/data-protection-training/create',
    element: (
      <ProtectedRoute>
        <AddDataProtectionTrainings />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/data-protection-training/edit/:id',
    element: (
      <ProtectedRoute>
        <AddDataProtectionTrainings />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/data-protection-banner',
    element: (
      <ProtectedRoute>
        <DataProtectionBanner />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/data-protection-banner/create',
    element: (
      <ProtectedRoute>
        <AddDataProtectionBanner />
      </ProtectedRoute>
    ),
  }, 
  {
    path: 'admin/data-protection-banner/edit/:id',
    element: (
      <ProtectedRoute>
        <AddDataProtectionBanner />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/data-protection-user-activity',
    element: (
      <ProtectedRoute>
        <DataProtectionUserActivity />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/organization-structure',
    element: (
      <ProtectedRoute>
        <OrganizationStructure />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/organization-structure/create',
    element: (
      <ProtectedRoute>
        <AddOrganizationStructure />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/organization-structure/edit/:id',
    element: (
      <ProtectedRoute>
        <AddOrganizationStructure />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/data-protection-good-reads',
    element: (
      <ProtectedRoute>
        <DataProtectionGoodReads />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/data-protection-good-reads/create',
    element: (
      <ProtectedRoute>
        <AddDataProtectionGoodReads />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/data-protection-good-reads/edit/:id',
    element: (
      <ProtectedRoute>
        <AddDataProtectionGoodReads />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/querty-in-veder',
    element: (
      <ProtectedRoute>
        <QuertyInVeder />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/querty-in-veder/create',
    element: (
      <ProtectedRoute>
        <AddQuertyInVedor />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/querty-in-veder/edit/:id',
    element: (
      <ProtectedRoute>
        <AddQuertyInVedor />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/opps-email',
    element: (
      <ProtectedRoute>
        <Opps />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/opps-email/create',
    element: (
      <ProtectedRoute>
        <AddOppsEmail />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/opps-email/edit/:id',
    element: (
      <ProtectedRoute>
        <AddOppsEmail />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/servey',
    element: (
      <ProtectedRoute>
        <Survay />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/user-activities',
    element: (
      <ProtectedRoute>
        <UserActivities />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-banners',
    element: (
      <ProtectedRoute>
        <OctoberBanners />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-banners/create',
    element: (
      <ProtectedRoute>
        <AddOctBanners />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-banners/edit/:id',
    element: (
      <ProtectedRoute>
        <AddOctBanners />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-games',
    element: (
      <ProtectedRoute>
        <OctGames />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-games/create',
    element: (
      <ProtectedRoute>
        <AddOctGames />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-games/edit/:id',
    element: (
      <ProtectedRoute>
        <AddOctGames />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-weeks',
    element: (
      <ProtectedRoute>
        <OctWeeks />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-weeks/create',
    element: (
      <ProtectedRoute>
        <AddOctWeeks />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-weeks/edit/:id',
    element: (
      <ProtectedRoute>
        <AddOctWeeks />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-events',
    element: (
      <ProtectedRoute>
        <OctDay />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-events/create',
    element: (
      <ProtectedRoute>
        <AddOctDays />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-events/edit/:id',
    element: (
      <ProtectedRoute>
        <AddOctDays />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/what-why-how',
    element: (
      <ProtectedRoute>
        <WhyWhatHow />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/what-why-how/create',
    element: (
      <ProtectedRoute>
        <AddWhatWhyHow />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/what-why-how/edit/:id',
    element: (
      <ProtectedRoute>
        <AddWhatWhyHow />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-pledge-video',
    element: (
      <ProtectedRoute>
        <OctPledgeVideo />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-pledge-video/create',
    element: (
      <ProtectedRoute>
        <AddOctPledgeVideo />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-pledge-video/edit/:id',
    element: (
      <ProtectedRoute>
        <AddOctPledgeVideo />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-webinar',
    element: (
      <ProtectedRoute>
        <Webinar />
      </ProtectedRoute>

    ),
  },
  {
    path: 'admin/october-webinar/create',
    element: (
      <ProtectedRoute>
        <AddWebinars />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-webinar/edit/:id',
    element: (
      <ProtectedRoute>
        <AddWebinars />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-gallary',
    element: (
      <ProtectedRoute>
        <OctGallary />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-gallary/create',
    element: (
      <ProtectedRoute>
        <AddOctGallary />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-gallary/edit/:id',
    element: (
      <ProtectedRoute>
        <AddOctGallary />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-contests',
    element: (
      <ProtectedRoute>
        <OctContest />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-contests/create',
    element: (
      <ProtectedRoute>
        <AddOctContests />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-contests/edit/:id',
    element: (
      <ProtectedRoute>
        <AddOctContests />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-contest-guidelines',
    element: (
      <ProtectedRoute>
        <OctContestsGuidelines />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-contest-guidelines/create',
    element: (
      <ProtectedRoute>
        <AddOctContestsGuidelines />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/october-contest-guidelines/edit/:id',
    element: (
      <ProtectedRoute>
        <AddOctContestsGuidelines />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/all-activites',
    element: (
      <ProtectedRoute>
        <AllActivites />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/all-activites/create',
    element: (
      <ProtectedRoute>
        <AddNewActivity />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/all-activites/edit/:id',
    element: (
      <ProtectedRoute>
        <AddNewActivity />
      </ProtectedRoute>
    ),
  },
  {
    path: 'admin/add-home-content',
    element: (
      <ProtectedRoute>
        <AddHomeContent />
      </ProtectedRoute>
    ),
  },
];

export default adminRoutes;
