import { Box, Modal, IconButton, Typography, Button } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { getImage } from 'csam/utils/Constants';
import LocaleContext from '../components/LocaleContext';

const OctoberMonthPopup: React.FC = () => {
  const [open, setOpen] = useState(true);
  const { locale } = useContext(LocaleContext);


  const handleClose = () => setOpen(false);

  useEffect(() => {
    setOpen(true);
  }, []);
  

  const handleNavigateOctMonth = () => {
    const newUrl = `/${locale}/2024`;
    window.location.href = newUrl;  
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="october-month-popup"
      aria-describedby="october-month-popup-description"
      className="octoberMonthModal"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          outline: 'none',
        }}
      >
        <Typography id="modal-modal-title" variant="h3" component="h2">
          October Month
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box>
          <Button onClick={handleNavigateOctMonth}>
            <img
              src={`${getImage}oct_landing_page_images/octoberMonthImage.jpg`}
              className="img-fluid mt-3"
              alt="Popup"
              style={{ width: '100%' }}
            />
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default OctoberMonthPopup;