import React, { useEffect } from 'react';
import './TrainingPlatform.css';
import Logo from 'csam/images/logo_img.png';
import { Typography } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { fixed } from 'csam/utils/Constants';
const LocalURL = '/SecurityTraining';
const TrainingPlatformAs: React.FC = () => {

  useEffect(() => {
    document.body.classList.add('hideFeedback');
    return () => {
      document.body.classList.remove('hideFeedback');
    };
  }, []);

  const { accounts } = useMsal();
  const user = accounts[0];
  const handleClick = async (language: string) => {
    if (!user) {
      console.error('No user found in MSAL accounts');
      return;
    }
    const activity = { action: `user clicked on ${language} training` };
    try {
      await fetch(`${fixed}proxy/updateActivity`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: user.username, username: user.username, activity, type: 'appsec' }),
      });
    } catch (error) {
      console.error('Error updating activity:', error);
    }
  };
  return (
    <div className="pb-5 trainingsContent">
      {' '}
      <header className="trainingsHeader">
        {' '}
        <div className="brand-logo p-5">
          {' '}
          <img src={Logo} alt="CSAM" />{' '}
        </div>{' '}
        <Typography className="common_title mb-4" variant="commonTitle" component="h4">
          {' '}
          WELCOME TO ABI 3rd PARTY LEARNING PLATFORM{' '}
        </Typography>{' '}
      </header>{' '}
      <div id="block">
        {' '}
        <fieldset id="one">
          {' '}
          <legend>Application Security Training</legend> <small className="d-block text-end">Choose a language</small>{' '}
          <a target="_blank" href={`${LocalURL}/v1_3_29Jun2023_English/story.html`} onClick={() => handleClick('')}>
            {' '}
            <div id="language">English Module</div>{' '}
          </a>{' '}
          <a target="_blank" href={`${LocalURL}/Apr2024_Chinese/story.html`} onClick={() => handleClick('')}>
            {' '}
            <div id="language">Chinese Module</div>{' '}
          </a>{' '}
          <a target="_blank" href={`${LocalURL}/Apr2024_Spanish/story.html`} onClick={() => handleClick('')}>
            {' '}
            <div id="language">Spanish Module</div>{' '}
          </a>{' '}
          <a target="_blank" href={`${LocalURL}/Apr2024_Portuguese/story.html`} onClick={() => handleClick('')}>
            {' '}
            <div id="language">Portuguese Module</div>{' '}
          </a>{' '}
        </fieldset>{' '}
      </div>{' '}
    </div>
  );
};
export default TrainingPlatformAs;
